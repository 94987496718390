@import "@ausmed/ausmed-styles/scss/variables";
@import "custom-lms-layout";

.card-hover  {
  &:hover {
    background-color: $neutral-200;
    cursor: pointer;
    .opacity {
      opacity: 1;
    }
  }
  .opacity {
    opacity: 0;
  }
}

.card {
  box-shadow: $card-box-shadow;
}

.card.br-4 {
  border-radius: 4px !important;
}

.card-reporting-size {
  width: 240px;
  height: 230px;
}