@import "./custom-lms-layout";

.alert-danger {
    background-color: $red-100;
    border-color: $red-800;
    color: $red-800;
}

.alert-danger a {
    color: $red-800 !important;
}

.alert-warning {
    background-color: $yellow-100;
    border-color: $yellow-800;
    color: $yellow-800;
}