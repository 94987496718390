.status-round-icon {
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.status-round-icon-small {
    border-radius: 50%;
    width: 8px;
    height: 8px;
}

.status-square-icon {
    border-radius: 2px;
    width: 9px;
    height: 9px;
  }
  