@import "./custom-lms-layout";
@import "bootstrap/scss/mixins";
/* Wrapper
 Padding will crash the checkboxes' style
*/
ng2-smart-table table tr th.ng2-smart-th {
  padding: 0.75rem 1rem !important ;
}

.min-width-100 ng2-smart-table table td {
  min-width: 100px;
}

.table-overflow {
  overflow: auto;
  width: 100%;
}
.table-wrap {
  background: white;
  border: $border-light;
  border-radius: $border-radius;
  overflow-x: auto;
  z-index: 10;
}

/* Tab header */
.custom-border-header {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border-left: $border-light;
  border-right: $border-light;
  border-top: $border-light;
}

/* Before pager */
ng2-smart-table table {
  border-bottom: $border-light;
}

/* Title */

table-cell-view-mode,
.ng2-smart-title {
  @extend .text-nowrap;
}

/* Row decoration */
.ng2-smart-row.selected {
  background: transparent !important;
  .table-sticky-column-left {
    background: white !important;
  }
}
.ng2-smart-row {
  &:hover {
    background: $neutral-200 !important;
    .table-sticky-column-right {
      opacity: 1;
      background: transparent !important;
    }
    .table-sticky-column-left {
      background: $neutral-200 !important;
    }
  }

  & {
    .table-sticky-column-right.always-display-cell {
      opacity: 1 !important;
      background: transparent !important;
    }
    .table-sticky-column-left.always-display-cell {
      opacity: 1 !important;
      background: transparent !important;
    }
  }
}
.ng2-smart-row:nth-child(2n) {
  // .table-sticky-column-right {
  //   background: $neutral-100;

  // }
  .table-sticky-column-left {
    background: $neutral-100;
  }

  & {
    background: $neutral-100;
  }
}

/* Sort */
.ng2-smart-sort-link.sort {
  color: $neutral-900;
  font-weight: $fw-medium !important;

  &::after {
    color: $neutral-900;
    content: url("../assets/images/sort-solid.svg");
  }
}
a.sort.asc::after,
a.sort.desc::after {
  border: 6px solid transparent;
  border-bottom-color: $neutral-900 !important;
  border-radius: 1px;
}

.ng2-smart-sort {
  font-weight: $fw-medium !important;
}

/* Pagination */
.ng2-smart-pagination,
.page-link-next,
.page-link-prev {
  font-size: unset !important;
}
ng2-smart-table-pager {
  margin: 0.75rem;
}

.ng2-smart-pagination.pagination .page-link {
  &:focus {
    box-shadow: none !important;
  }
}

.page-item.disabled .page-link {
  background: $neutral-300 !important;

  & span {
    color: $neutral-600 !important;
  }
}

// Changing the name of this class will affect its hover interaction, see rules above
// If you need to change anything related to sticky service, please let @Yipin know!!!
.table-sticky-column-right {
  position: sticky;
  right: 0;
  background: white;
  opacity: 0;
  z-index: 100;

  // td:nth-child(2n) {
  //   background: $neutral-100;
  // }
}

// If you need to change anything related to sticky service, please let @Yipin know!!!
.table-sticky-column-right.hover-to-display-cell {
  position: sticky;
  right: 0;
  opacity: 0;
  display: table-cell;
  padding-left: 0px;

  td:nth-child(2n) {
    background: $neutral-100;
  }
}

.table-sticky-column-left {
  position: sticky;
  z-index: 100;
  left: 0;
  background: white;
  padding: 0.75rem 1rem !important;
  @extend .text-truncate;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  max-width: 900px;

  @include media-breakpoint-down(lg) {
    max-width: 400px;
  }
  @include media-breakpoint-down(md) {
    max-width: 300px;
  }
  @include media-breakpoint-down(sm) {
    max-width: 200px;
  }

  td:nth-child(2n) {
    background: $neutral-100;
  }
}

// for multi col sticky
.table-sticky-column-left + :not(.table-sticky-column-left) {
  border-left: 1px solid #e6e7ea !important;
}

.sticky-column-left-shadow :not(.ng2-smart-actions).table-sticky-column-left {
  box-shadow: 0.1rem 0 0.5em rgba(0, 0, 0, 0.3);
  clip-path: inset(0px -10px 0px 0);
  border-right: 1px solid #e6e7ea;

  // nicer shadow but shows line
  // box-shadow: 0.2rem 0.15rem 0.5em rgba(0, 0, 0, 0.4);
  // clip-path: polygon(0 0, 120% 0, 120% 120%, 100% 120%, 100% 100%, 0 100%);
}

.checkbox-column {
  box-shadow: none !important;
  border-right: 2px solid transparent !important;
  z-index: 101 !important;
}

.sticky-column-left-shadow .rt-user-avatar-column {
  display: block;
  // box-shadow: 0 0.5rem 0.5em rgba(0, 0, 0, 0.4) !important;
  clip-path: inset(-10px -10px -10px 0);
  border-right: 1px solid #e6e7ea !important;
  background-color: white;
}

.sticky-column-left-shadow {
  .rt-tbody-row:nth-child(2n) .rt-user-avatar-column {
    background-color: $neutral-100 !important;
  }

  .rt-tbody-row.hover-rt-row .rt-user-avatar-column {
    background-color: $neutral-200 !important;
  }
}

// hide bottom shadow of bottom row
.ng2-smart-row:last-child > .table-sticky-column-left {
  clip-path: inset(-1px -15px 0px 0px);
}

ng2-smart-table table {
  border-collapse: separate !important;
}

.ng2-smart-action-multiple-select {
  text-align: center;
}

// table checkbox
.ng2-smart-action-multiple-select > input {
  width: 20px;
  height: 20px;
  display: display-outside;
}

// table header checkbox
ng2-smart-table th > input {
  @extend .form-control;
  width: 20px;
  height: 20px;
  display: display-outside;
}

/* Fixed column widths starts*/
.ng2-smart-title {
  width: 134px !important;
  // overflow: hidden;
  // text-overflow: ellipsis;
}

// medium columns
.orgUser .ng2-smart-title,
.user .ng2-smart-title,
.displayName .ng2-smart-title,
.email .ng2-smart-title,
.assigns .ng2-smart-title,
.teamNames .ng2-smart-title,
.teams .ng2-smart-title,
.jobRoles .ng2-smart-title,
.displayFrequency .ng2-smart-title,
.enrolDate .ng2-smart-title,
.latestCompleteDate .ng2-smart-title,
.mtPlanCell .ng2-smart-title,
.invitationLink .ng2-smart-title,
.jobRoleTitles .ng2-smart-title {
  width: 216px !important;
}

// large columns
.resource .ng2-smart-title,
.resourceTitle .ng2-smart-title,
.managerNames .ng2-smart-title,
.content .ng2-smart-title,
.fn_title .ng2-smart-title,
.title .ng2-smart-title {
  width: 299px !important;
}

.feedbackContent .ng2-smart-title {
  width: 590px !important;
}

// long column headers
.min .ng2-smart-title,
.createDateLocal .ng2-smart-title,
.actTotalMin .ng2-smart-title,
.actNum .ng2-smart-title,
.earlyRecogDate .ng2-smart-title,
.complianceStatus .ng2-smart-title,
.earlyRecogDateUTC .ng2-smart-title,
.mtAssignID .ng2-smart-title,
.assignID .ng2-smart-title,
.trainingPlanName .ng2-smart-title,
.recordTotalNum .ng2-smart-title,
.completionTime .ng2-smart-title,
.earlyRecogDuration .ng2-smart-title,
.frequencyOfLearning .ng2-smart-title,
.fn_status .ng2-smart-title,
.totalAckNum .ng2-smart-title,
.linkedDocs .ng2-smart-title,
.requiredJobRoles .ng2-smart-title,
.enrolledUsers .ng2-smart-title,
.resourceDuration .ng2-smart-title,
.completionRate .ng2-smart-title,
.extendedDates .ng2-smart-title,
.activityNum .ng2-smart-title,
.startDate .ng2-smart-title,
.ackRate .ng2-smart-title,
.endDate .ng2-smart-title {
  width: auto !important;
}
/*Fixed column widths ends*/

.extensions .ng2-smart-title,
.daysExtended .ng2-smart-title {
  text-align: center;
}

/* Truncated column headers with sorting */

// .min .ng2-smart-title a,
// .createDateLocal .ng2-smart-title a {
//   width: 100px; // give extra space for sorting symbol
//   float: left;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

// move sorting symbol to before
// .min .ng2-smart-title a::before,
// .createDateLocal .ng2-smart-title a::before {
//   content: url('../assets/images/sort-solid.svg');
//   float: right;
// }

// clip top for desc
// .min a.sort.desc::before,
// .createDateLocal a.sort.desc::before {
//   clip-path: inset(50% 0 0 0);
// }

// .min a.sort.asc::before,
// .createDateLocal a.sort.asc::before {
//   clip-path: inset(0 0 50% 0);
// }

// hide original symbol in ::after
// .min a.sort.asc::after, .min a.sort.desc::after,
// .createDateLocal a.sort.asc::after, .createDateLocal a.sort.desc::after {
//   visibility: hidden;
// }

// .min .ng2-smart-title a:hover,
// .createDateLocal .ng2-smart-title a:hover {
//   overflow:visible;
//   width: auto !important;
// }

// give solid background so it overlays next column title when hovered
// .min .ng2-smart-title a,
// .createDateLocal .ng2-smart-title a {
//   background-color: rgb(255, 255, 255) !important;
// }

// .min .ng2-smart-title:hover,
// .createDateLocal .ng2-smart-title:hover
//  {
// width: 134px !important;
// overflow:visible;
// text-overflow: ;
// width: auto !important;
// position: absolute;
// z-index: 1000 !important;
// position: relative;
// text-overflow: ellipsis;
// }

/*Truncated column header with sorting ends*/

// sticky No Data Found text
// Note: if table has multi-select, change display to table-cell
ng2-smart-table td[colspan] {
  position: sticky;
  z-index: 100;
  max-width: 900px;
  left: 0 !important;
  // right: 100%;
  background: white;
  padding: 0.75rem 1rem;
  display: block;
}

.sticky-multi-select td[colspan] {
  display: table-cell;
}

// td[colspan]::after {
//   content: 'test';
//   position: relative;
// }

// td[colspan]::before {
//   position: sticky;
//   left: 16px;
//   content: 'No Data Found';
//   color: black;
// }

// ngb popover
.popover {
  z-index: 1100 !important;
}

.popover-header {
  padding: 12px;
}

.popover-body {
  padding: 18px 12px;
}

.popover-body div:not(:last-child) {
  margin-bottom: 12px;
}

/*Table tooltip*/
.table-custom-tooltip .tooltip-inner {
  background: $dark;
  color: $white;
  font-size: $small;
  text-align: left !important;
  line-height: 1.25rem;
  max-width: 500px;
  padding: 0.75rem;
  border-radius: $border-radius;

  @include media-breakpoint-up(lg) {
    min-width: 300px;
  }
}

.table-custom-tooltip .arrow::before {
  content: none !important;
}

// hide sticky button before sticky class is applied to avoid flashing effect
.table-transparent-button {
  opacity: 0;
}

.table-sticky-column-right .table-transparent-button {
  opacity: 1;
}

// table floating scroll
div.fl-scrolls {
  z-index: 1000;
}
