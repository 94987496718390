/* Remove when rebrand done */

//.sub-input label {
//   display: flex;
//   align-items: center;
//   padding: 0.5rem;
//   border-width: $input-border-width;
//   border-style: solid;
//   border-color: $border-color;
//   border-radius: $border-radius;

//   &:hover {
//     cursor: pointer;
//   }
// }

// .sub-input input[type="checkbox"],
// .sub-input input[type="radio"] {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   min-width: 16px;
//   height: 16px;
//   margin-right: 1rem !important;
//   color: $secondary;
//   background-color: $white;
//   box-shadow: 0 0 0 1px $secondary;
//   outline: none;
//   border: none !important;
//   border-radius: 50%;

//   &:before {
//     opacity: 0;
//     background: $secondary;
//     width: 14px;
//     height: 14px;
//     border-radius: 50%;
//   }

//   &:checked {
//     box-shadow: 0 0 0 2px darken($secondary, 5);
//     &+.border{
//       border: 2px solid $secondary !important;
//     }

//     &:before {
//       content: "";
//       opacity: 1;
//     }
//   }
// }

@import "@ausmed/ausmed-styles/scss/variables";

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    @extend .form-label !optional;
}

// input font size
.form-control {
    @extend .f-small;
    border-radius: 4px;
}

// checkbox colour
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: $brand-blue-default !important;
    border-color: $brand-blue-default !important;
}

// custom checkbox (toggle)
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 21, 64, 0.25) !important;
}

.custom-control-input ~ .custom-control-label::before {
    border-color: rgba(0, 21, 64, 0.25) !important;
}

// focus input colour
.form-control:focus {
    border-color: $brand-blue-default;
    box-shadow: 0 0 0 0.2rem rgba(0, 21, 64, 0.25);
}

// focus ng-select
// .ng-select.ng-select-opened {
//     @extend .form-control:focus;
// }

// mat toggle colour (resource details page)
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $brand-blue-default !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba($brand-blue-default, 0.54) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-ripple .mat-ripple-element {
    background-color: rgba(29, 135, 229, 0.2);
}

.mat-radio-outer-circle {
    border-color: $brand-blue-default !important;
}

.mat-radio-inner-circle {
    background-color: $brand-blue-default !important;
}

// invalid text input
.is-invalid {
    border-color: $red-600 !important;
    background-image: none !important;
    padding-right: inherit !important;
}

// invalid ng-select
.ng-select.is-invalid > .ng-select-container {
    @extend .is-invalid;
}
// .ng-touched .ng-invalid:not(.ng-untouched) {
//     @extend .is-invalid;
// }

.text-danger {
    color: $red-600 !important;
}

// error message under text input
.text-error-input {
    @extend .f-caption;
    color: $red-600 !important;
}

.is-valid {
    border-color: $green-600 !important;
    background-image: none !important;
    padding-right: inherit !important;
}

// .ng-invalid .ng-touched {
//     border-color: red;
// }
.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.25rem;
    padding-left: 1.5rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.125rem;
    opacity: 0;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

.custom-control-label::after {
    position: absolute;
    top: 0.125rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50%/50% 50% no-repeat;
}

.custom-control-label::before {
    position: absolute;
    top: 0.125rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #bdc2cc solid 1px;
}

.custom-control-label::before,
.custom-file-label,
.custom-select,
.ng-select .ng-arrow-wrapper {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-label::after {
    top: calc(0.125rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #bdc2cc;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-control-input:disabled ~ .custom-control-label {
    cursor: not-allowed;
    pointer-events: all !important;
}
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: $neutral-200;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

label {
    margin-bottom: 0.5rem;
}

// copied from old _custom-forms.scss
.custom-select,
.ng-select .ng-arrow-wrapper {
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234E545F' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        right 1rem center/8px 10px no-repeat;
}

// copied from old _custom-forms
.custom-switch {
    padding-left: 2.25rem;
}

// default color is Secondary color
.form-check-input:checked {
  background-color: $secondary !important;
  border-color: $secondary !important;
}

.form-check-input:focus {
  border-color: $blue-200;
  outline: 0;
  box-shadow: 0 0 0 0.25rem $blue-100;
}

.form-toggle-green {
    .form-check-input:checked {
        background-color: $green-600 !important;
        border-color: $green-600 !important;
    }
    .form-check-input:focus {
        border-color: $green-300 !important;
        opacity: 0.6;
        box-shadow: 0 0 0 0.25rem $green-300;
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba%220, 245, 230%29'/></svg>") !important;
    }
}

.form-switch .form-check-input:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}
