@import "./custom-lms-layout";
/* Badges */
$badge-font-size:                   $caption;
$badge-font-weight:                 $fw-semibold;
$badge-padding-y:                   4px;
$badge-padding-x:                   6px;
$badge-border-radius:               4px;

@mixin badge-text-and-bg-color($badge-text-color, $badge-bg-color) {
  font-weight: $fw-semibold;
  border-radius: $badge-border-radius;
  color: $badge-text-color;
  font-size: $badge-font-size;
  background-color: $badge-bg-color;
}

.badge-primary {
  @include badge-text-and-bg-color(white, $m-blueberry-darkest);
}

.badge-primary-light {
  @include badge-text-and-bg-color($m-blueberry-darkest, $m-blueberry-lightest);
}

.badge-success {
  @include badge-text-and-bg-color(white, $green-600);
}

.badge-success-light {
  @include badge-text-and-bg-color($green-800, $green-100);
}

.badge-danger {
  @include badge-text-and-bg-color(white, $red-800);
}

.badge-danger-light {
  @include badge-text-and-bg-color($red-800, $red-100);
}

.badge-warning {
  @include badge-text-and-bg-color($neutral-900, $yellow-600);
}

.badge-warning-light {
  @include badge-text-and-bg-color($neutral-900, $yellow-100);
}

.badge-inactive {
  @include badge-text-and-bg-color(white, $neutral-700);
}

.badge-inactive-light {
  @include badge-text-and-bg-color($neutral-900, $neutral-300);
}

.badge-info-light {
  @include badge-text-and-bg-color($blue-700, $blue-100);
}

/** This pill will also be used as the active tab badge pill;
* in the original bootstrap, badge-pill is the modifier to change the border-radius and the padding of badges;
* I have checked with the Designer, in B2B, we make it a reserved badge style
* If you need to modify the badge radius, do it locally or add a new badge style for it!
*/
.badge-pill {
  @include badge-text-and-bg-color($m-blueberry-darkest, $m-blueberry-lightest);
  border-radius: 32px !important;
}

/* Inactive Tab color */
.badge-light {
  color: $neutral-900 !important;
  font-weight: $fw-semibold;
  background-color: $neutral-300;
}

.badge-neutral-500 {
  color: $neutral-900 !important;
}




