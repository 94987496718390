@import "custom-lms-layout";

/* import from lms-layout to ensure you really override the bootstrap styles! */

.nav-tabs .nav-link.active {
  color: $nav-tabs-link-active-color;
  border: none;
  border-bottom: $nav-tabs-border-width solid $nav-tabs-link-active-border-color;
}
.nav-tabs .nav-link {
  font-size: $nav-link-font-size;
  font-weight: $nav-link-font-weight;
  color: $nav-link-color;
  border: none;
  border-bottom: $nav-tabs-border-width solid transparent;
}
.nav-tabs {
  @extend .children-gap-6;
}

.nav-link {
  display: inline-flex !important;
  align-items: center !important;
  gap: 0.25rem;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: unset;
}

.status-round-icon {
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.status-round-icon-small {
  border-radius: 50%;
  width: 8px;
  height: 8px;
}

.nav-red .nav-link.active {
  color: $red-500;
  border-top: 0.25rem solid $red-500 !important;
  }

.nav-yellow .nav-link.active {
  color: $yellow-500;
  border-top: 0.25rem solid $yellow-500 !important;
  }
