@import "custom-lms-layout";

.modal-header {
  /* padding: $modal-inner-padding; */
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.modal-content {
  width: 100%;
  border-radius: 8px;
}

.modal-body {
  padding: 0 $modal-inner-padding;
}

.modal-header,
.modal-footer {
  border: none !important;
}

.modal-footer > * {
  margin: inherit;
}

.modal-footer {
  padding: $modal-inner-padding;
  display: inherit;
}

.modal-header .progress {
  background-color: $neutral-300;
  border-radius: 8px;
  height: 16px;
}

.modal-header .progress-bar {
  color: $neutral-300;
  background-color: $brand-blue-default;
}

.modal-dialog {
  min-height: calc(100% - (1.25rem * 2));
  margin: 1.25rem auto;
  display: flex;
  align-items: center;
}

// used when opening modal with option size: 'schedule'
.modal-schedule {
  --bs-modal-width: 962px;
  // .modal-content {
  //   width: 962px;
  // }
}

// used when opening modal with option size: 'schedule'
.modal-95 {
  --bs-modal-width: 95%;
}