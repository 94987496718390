@import "@ausmed/ausmed-styles/scss/variables";

/* Borders */
$border-radius: 0.1875rem; // 3px
$border-radius-lg: 0.375rem; // 6px
$border-radius-xl: 8px; // 6px
$border-light: 1px solid $neutral-300;
$border-medium: 1px solid $neutral-400;
$border-dark: 1px solid $neutral-500;

/* Side Panel (Ausmed-CPD website) */
$sidebar-width: 290px;
$sidebar-bg-color: $light;
$lms-sidenav-width: 232px;
$lms-narrow-sidenav-width: 96px;
$lms-header-height: 64px;
/* Primary Navigation */
$location-banner-height: 22px;
$primary-nav-height: 72px;
$page-nav-height: auto;
/* Cards */
$card-cap-bg: $white;
$card-border-color: none;
$card-border-width: 0px;
$card-border-radius: $border-radius-lg;
$card-box-shadow: 0px 2px 1px rgba($black, 0.05), 0px 0px 1px rgba($black, 0.25);

/* Modals */
$modal-inner-padding: $spacer * 1.25;
$modal-content-border-color: transparent;
$modal-content-border-radius: $border-radius-lg;
$modal-scale-transform: scale(1);
$modal-backdrop-bg: $dark;
$modal-fade-transform: none;
$modal-show-transform: none;
$modal-transition: none;
$modal-backdrop-opacity: 0.3;
/* Progress Bar */
$progress-height: 16px;
$progress-bg: $neutral-300;
$progress-border-radius: $border-radius-xl;
$progress-bar-color: $m-blueberry-default;
$progress-bar-bg: $m-blueberry-default;
/* Dropdowns */
$dropdown-link-color: $neutral-900;
$dropdown-link-hover-color: $neutral-900;
$dropdown-link-active-bg: $neutral-200;
$dropdown-link-active-color: $neutral-900;
$dropdown-link-hover-bg: $neutral-200;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 1rem;
/* Pagination */
$pagination-color: $neutral-700 !important;
$pagination-bg: $white;
$pagination-margin-left: 0px;
$pagination-border-color: $neutral-300;
$pagination-focus-color: $white;
$pagination-focus-bg: $neutral-200;
$pagination-hover-bg: $neutral-200;
$pagination-hover-border-color: none;
$pagination-active-color: $neutral-900 !important;
$pagination-active-bg: $neutral-200 !important;
$pagination-active-border-color: $neutral-300;
/* Button Overrides */
$btn-font-weight: $fw-medium;
$btn-line-height: $line-height-base;
$btn-font-size: $small;
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $small;
$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: $paragraph;
$input-border-color: $neutral-500;
$link-color: $neutral-900;
$link-hover-color: $pink-800;
$link-decoration: none;
$link-hover-decoration: underline;
$link-font-weight: $fw-medium;
$btn-transition: none;
$nav-tabs-link-active-color: $m-blueberry-darkest;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: $m-blueberry-darkest;
$nav-link-padding-y: 1.25rem;
$nav-link-padding-x: 0rem;
$nav-link-font-size: $small;
$nav-link-font-weight: $fw-semibold;
$nav-link-color: $neutral-700;
$nav-link-disabled-color: $neutral-600;
$nav-tabs-border-width: 0.25rem;
$nav-tabs-border-color: transparent;

$enable-pointer-cursor-for-buttons: true !default;

$zindex-modal-dropdown: 1060;


// new side panel
.side-panel-header {
    padding: 16px;
    background-color: $neutral-100;
    border-bottom: solid 1px $neutral-400;
    max-height: 84px;
}
