@import "bootstrap/scss/mixins";
.accordion-header:not(.collapsed) {
    color: inherit;
    background-color: $neutral-100;
    // box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-toggle:not(.collapsed).according-header-left:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg id='Regular' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3C/defs%3E%3Ctitle%3Earrow-down-1%3C/title%3E%3Cpath d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    width: 20px;
    height: 15px;
    background-repeat: no-repeat;
}

.accordion-toggle.collapsed.according-header-left:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg id='Regular' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3C/defs%3E%3Ctitle%3Earrow-down-1%3C/title%3E%3Cpath d='M23.25,7.311,12.53,18.03a.749.749,0,0,1-1.06,0L.75,7.311' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
    transform: rotate(-90deg);
    width: 20px;
    height:15px;
    background-repeat: no-repeat;
}
