@import "node_modules/@ausmed/ausmed-styles/scss/variables";

.cal-month-view .cal-day-cell.cal-today {
    background-color: $neutral-100;
}

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
    background-color: $pink;
    border-radius: 4px;
    color: $white;
    font-size: inherit;
}

.cal-month-view .cal-day-number {
    opacity: inherit;
    margin: inherit;
    float: inherit;
}

.cal-month-view .cal-header .cal-cell {
    padding: 0.75rem 0;
    font-weight: bold;
    background-color: $neutral-200;
    border-right: 1px solid;
    border-right-color: #e1e1e1;
}

.cal-month-view .cal-header .cal-cell:hover {
    background-color: $neutral-200;
}

.cal-month-view .cal-day-cell {
    background-color: $neutral-100;
    min-height: 100px;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: $neutral-100;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: inherit;
}
