@import "custom-tables";
@import "bootstrap/scss/mixins";
@import "./custom-lms-layout";

a:hover {
  text-decoration: underline;
  color: inherit !important;
}

.has-default-cursor {
  cursor: default !important;
}

.has-help-cursor {
  cursor: help;
}

.has-pointer {
  cursor: pointer !important;
}

.has-not-allowed-cursor {
  cursor: not-allowed !important;
}

.modal-footer,
.modal-header {
  @include media-breakpoint-up(md) {
    //padding: 0 rem;
  }
}

.modal-body {
  @include media-breakpoint-up(md) {
    //padding: 0 rem;
  }
}

.sidenav-container {
  // remove the effect of header padding
  height: calc(100vh - #{$lms-header-height});
  &.settings-sidenav {
    height: calc(100vh - #{$lms-header-height} - 109px); // settings header height
  }
}

.sidenav {
  width: min-content;
  @include media-breakpoint-down(sm) {
    width: 100vw;
  }
}

.dropdown-item {
  @extend .f-small;
  padding: 0.5rem 0.75rem !important;
  font-weight: $fw-medium !important;
}

.dropdown-item-inactive {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  color: #27313a;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item-inactive.inactive:hover {
  background: white !important;
  cursor: not-allowed !important;
}

// UTILITY - Places divider dot between metadata

.divider-dot > * {
  &:not(:last-child) {
    &:after {
      margin-right: 8px;
      margin-left: 8px;
      content: "•";
      display: inline-block;
    }
  }
}

// UTILITY - Gap replacement

.children-horizontal-gap-1 > * {
  &:not(:last-child) {
    @extend .mb-1 !optional;
  }
}

.children-horizontal-gap-2 > * {
  &:not(:last-child) {
    @extend .mb-2 !optional;
  }
}
.children-horizontal-gap-4 > * {
  &:not(:last-child) {
    @extend .mb-4 !optional;
  }
}

.children-horizontal-gap-8 > * {
  &:not(:last-child) {
    @extend .mb-8 !optional;
  }
}

.children-horizontal-gap-10 > * {
  &:not(:last-child) {
    @extend .mb-10 !optional;
  }
}

.children-gap-1 > * {
  &:not(:last-child) {
    @extend .me-1 !optional;
  }
}

.children-gap-2 > * {
  &:not(:last-child) {
    @extend .me-2 !optional;
  }
}

.children-gap-3 > * {
  &:not(:last-child) {
    @extend .me-3 !optional;
  }
}

.children-gap-4 > * {
  &:not(:last-child) {
    @extend .me-4 !optional;
  }
}

.children-gap-bottom-5 > * {
  &:not(:last-child) {
    @extend .mb-5 !optional;
  }
}

.children-gap-7 > * {
  &:not(:last-child) {
    @extend .me-7 !optional;
  }
}

.width-max-content {
  width: max-content !important;
}

.ngb-dp-weekday {
  color: $neutral-900 !important;
  font-weight: normal;
}

// Course Builder drag drop related

.drag-list {
  max-width: 100%;
  min-height: 60px;
  display: block;
  overflow: hidden;
}

.drag-control {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  color: $dark;
  width: 24px;
  background: $neutral-200;
  display: flex;
  align-items: center;
  cursor: move;
  border-top-left-radius: calc(#{$border-radius} - 1px);
  border-bottom-left-radius: calc(#{$border-radius} - 1px);
}

.cdk-drag-preview {
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  background: white;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.hover-underline:hover {
  text-decoration: underline;
}

.remove-dropdown-arrow {
  &.dropdown-toggle::after {
    display: none;
    content: "";
  }
}

// .card app-row-card .border-bottom:last-child {
//   border-bottom: none !important;
// }

.underline {
  text-decoration: underline;
}

.btn-link-light {
  padding: 0.25rem 0 !important;
  color: $neutral-800 !important;
  font-weight: $fw-normal !important;

  &:hover {
    text-decoration: underline;
  }
}

.flex-direction-column {
  flex-direction: column;
}

.cursor-move {
  cursor: move !important;
}

.cb-active-section {
  background: $blue-100;
}

.cb-active-section-bar {
  display: none;

  .cb-active-section & {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background: $blue-600;
    z-index: 1;
  }
}

.course-builder-display-name-truncated {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.min-width-0 {
  min-width: 0 !important;
}

.custom-tooltip .arrow::before {
  content: none;
}

.custom-tooltip-no-max-width .arrow::before {
  content: none;
}

.custom-tooltip .tooltip-inner {
  border-radius: 4px;
  color: $white;
  background: $neutral-900;
  font-size: $small;
  font-weight: $fw-medium;
  max-width: 300px;
  min-width: 0;

  text-align: left;
  line-height: 1.25rem;
  padding: 8px;
}

.custom-tooltip .tooltip-title {
  @extend .f-paragraph;
  @extend .fw-semibold;
  @extend .text-white;
}
.form-control.ng-invalid.show-invalid {
  @extend .is-invalid;
}

.hover-to-display-drag-handler .cdk-drag-handle {
  visibility: hidden;
}

.hover-to-display-drag-handler:hover .cdk-drag-handle {
  visibility: visible;
}

.min-height-100.ng-dropdown-panel .ng-dropdown-panel-items {
  min-height: 100px;
}

.sticky-header-container {
  overflow-y: scroll;
  // +2px: remove the effect of header padding
  height: calc(100vh - #{$lms-header-height + 2px});

  .side-info {
    display: none;
    @extend .ms-auto !optional;
  }

  &.sticky-top {
    #sticky-header {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      clip-path: inset(-10px -10px -10px 0);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #e6e7ea !important;
    }

    .side-info {
      display: inline-block !important;
    }
  }
}

@mixin smart-table-sort-universal {
  border: 4px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.3);
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 4px;
  width: 0;
  border-bottom-color: #171d29 !important;
  border-radius: 1px;
}

.smart-table-like-sort {
  &.asc::after {
    @include smart-table-sort-universal;
    margin-bottom: 2px;
  }
  &.desc::after {
    @include smart-table-sort-universal;
    margin-bottom: -2px;
    transform: rotate(-180deg);
  }
}

.btn.square-button {
  padding: 0.4rem 0.5rem;
}

.inner-html-font-style {
  font-size: $small;
  color: $neutral-800;

  h3,
  h4,
  h5 {
    font-size: $small;
    font-weight: $fw-medium;
  }

  li,
  p {
    font-size: $small;
    color: $neutral-800;
  }
}

.pdfViewer > div.page {
  box-sizing: content-box;
}

.bg-primary {
  background-color: $primary !important;
}

.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #3f51b5 !important;
}

// angular material progress bar
.mat-progress-bar {
  .mat-progress-bar-buffer {
    background-color: $neutral-400 !important;
  }

  .mat-progress-bar-fill::after {
    background-color: #3f51b5 !important;
  }

  .mat-progress-bar-background {
    fill: neutral-400 !important;
  }
}

.form-control:disabled,
ng2-smart-table th > input:disabled {
  color: $neutral-600;
  cursor: not-allowed;
  pointer-events: all !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-danger {
  background-color: $danger !important;
}

// TEXT RELATED
.underlined-link {
  @extend .f-small;
  @extend .fw-semibold;
  @extend .cursor-pointer;
  border-bottom: 1px $neutral-500 solid;
  color: $neutral-900;
  // &:hover {
  //   color: $neutral-900;
  // }
}

.m-first-letter-add-L:before {
  content: '┗';
  display: inline-block;
  transform: translateY(1px);
  padding-right: 2px;
}

// full-page-flow
.full-page-flow-height {
    min-height: calc(100vh - #{$lms-header-height});
}

.max-content-width {
  display: flex;
  justify-content: center;
  padding: 48px;
}

.max-content-width > * {
  width: 1140px;
}
