// TODO: replace unresolved vars
@use "sass:math";
@import "custom-lms-layout";

.swiper-container {
  padding: 1px;
  margin-left: -1px;
  margin-right: -1px;
}

.swiper-button {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  display: flex;
  align-items: center;
  height: 100%;
  font-size: $h2;
  padding: 0 1rem;
  cursor: pointer;
  z-index: 2;
  transition: 50ms;
  color: $white;
  background: rgba($white, 0.6);
  border-radius: $border-radius;

  &:hover {
    font-size: $h2;
    color: $white;
    background: rgba($white, 0.8);
  }

  &.is-disabled {
    cursor: auto;
    pointer-events: none;
    opacity: 0.5;
  }
}

.swiper-button--prev {
  left: 0;

  &:hover {
    padding: 0 1.5rem 0 1.25rem;
  }
}

.swiper-button--next {
  right: 0;

  &:hover {
    padding: 0 1.25rem 0 1.5rem;
  }
}

@media all and (max-width: 576px) {
  .swiper-container {
    padding: 1px 2rem 1px 0;
  }

  .swiper-button {
    display: none;
  }
}

//  Featured
$slides-per-view: 1.2;
$slide-width: math.div(1, $slides-per-view) * 100;
$slide-margin: (100 - $slide-width) * 0.5;

.au-swiper__image {
  width: 100%;
}

.au-swiper__view {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  display: block;
  font-size: $paragraph;
  pointer-events: none;
  color: $white;
  font-weight: 600;
  background-color: rgba($white, 0.4);
  border-radius: 10%;
  padding: 0.2rem 0.8rem;
  bottom: 1rem;
  right: 1rem;

}

@media all and (min-width: 576px) {
  .au-swiper__view {
    font-size: $h2;
  }
}

@media all and (min-width: 768px) {
  .au-swiper__view {
    right: calc(#{$slide-margin} * 100% + 1rem);
  }
}

// makes image invisible so that it can be used for caching
.au-swiper__cacher {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0.01;
}
