// @import "@ng-select/ng-select/themes/default.theme.css";
@import "./custom-lms-layout";
// NG SELECT UPDATES

@mixin rtl {
  @at-root [dir="rtl"] #{&} {
    @content;
  }
}

$ng-select-highlight: $blue-200 !important;
$ng-select-primary-text: $dark !important;
$ng-select-disabled-text: $neutral-200 !important;
$ng-select-border: $neutral-500 !important;
$ng-select-border-radius: $border-radius !important;
$ng-select-bg: $white !important;
$ng-select-selected: $neutral-200 !important;
$ng-select-marked: $neutral-200 !important;
$ng-select-box-shadow: $box-shadow;
$ng-select-placeholder: $neutral-600 !important;
$ng-select-height: 36px !important;
$ng-select-value-padding-left: 8px !important;
$ng-select-value-font-size: $small !important;

.ng-select.auto-grow {
  max-width: inherit;

  .ng-dropdown-panel {
    width: auto;
    min-width: 200px;
  }
}

.ng-select.enable-error-border.ng-invalid.ng-touched {
  .ng-select-container {
    border: 1px solid $red !important;
  }
}

.ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      .ng-value-container {
        flex-wrap: wrap;
        min-width: 0;

        .ng-value {
          white-space: nowrap;
          min-width: 0;
          display: flex;
          max-width: 95%;

          .ng-value-label {
            // @include wrapt-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        flex-wrap: wrap;
        min-width: 0;

        .ng-value {
          white-space: nowrap;
          min-width: 0;
          display: flex;
          //max-width: 95%;

          .ng-value-label {
            // @include wrapt-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &.ng-select-opened {
    // outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 21, 64, 0.25);
    border-radius: $ng-select-border-radius;

    > .ng-select-container {
      background: $ng-select-bg;
      border-color: $brand-blue-default !important;

      &:hover {
        box-shadow: none;
      }

      .ng-arrow {
        top: -2px;
        border-color: $ng-select-border;
        border-width: 0 4px 4px;

        &:hover {
          border-color: $ng-select-border;
        }
      }
    }

    &.ng-select-bottom {
      > .ng-select-container {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.ng-select-top {
      > .ng-select-container {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: $ng-select-highlight;
      box-shadow: none;
    }
  }

  &.ng-select-disabled {
    > .ng-select-container {
      background-color: $ng-select-disabled-text;
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-select-container {
    color: $ng-select-primary-text;
    background-color: $ng-select-bg;
    border-radius: $ng-select-border-radius;
    border: 1px solid $ng-select-border;
    min-height: $ng-select-height;
    align-items: center;

    &:hover {
      box-shadow: none;
      cursor: text;
    }

    .ng-value-container {
      align-items: center;
      padding-left: $ng-select-value-padding-left;
      @include rtl {
        padding-right: $ng-select-value-padding-left;
        padding-left: 0;
      }

      .ng-placeholder {
        color: $ng-select-placeholder;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      height: $ng-select-height;

      .ng-value-container {
        .ng-input {
          // top: 4px;
          // left: 0;
          padding-left: $ng-select-value-padding-left;
          padding-right: 50px;
          @include rtl {
            padding-right: $ng-select-value-padding-left;
            padding-left: 50px;
          }
        }
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      > .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-disabled-text;
        border: 1px solid $ng-select-border;

        .ng-value-label {
          padding: 2px 4px;
        }
      }
    }

    .ng-select-container {
      padding-top: 4px;
      //padding-bottom: 4px;
      .ng-value-container {
        // padding-top: 4px;
        padding-left: 8px;
        @include rtl {
          padding-right: 8px;
          padding-left: 0;
        }

        .ng-value {
          font-size: $ng-select-value-font-size;
          margin-bottom: 4px;
          padding: 2px;
          background-color: $ng-select-selected;
          border-radius: 3px;
          margin-right: 4px;
          @include rtl {
            margin-right: 0;
            margin-left: 4px;
          }

          &.ng-value-disabled {
            background-color: $ng-select-disabled-text;

            .ng-value-label {
              padding-left: 4px;
              @include rtl {
                padding-left: 0;
                padding-right: 4px;
              }
            }
          }

          .ng-value-label {
            display: inline-block;
            padding: 1px 4px;
          }

          .ng-value-icon {
            display: inline-block;
            padding: 1px 4px;

            &:hover {
              background-color: $ng-select-selected;
            }

            &.left {
              border-top-left-radius: $ng-select-border-radius;
              border-bottom-left-radius: $ng-select-border-radius;

              border-right: $ng-select-selected;
              @include rtl {
                border-left: $ng-select-selected;
                border-right: none;
              }
            }

            &.right {
              border-left: $ng-select-selected;
              @include rtl {
                border-left: 0;
                border-right: $ng-select-selected;
              }
            }
          }
        }

        .ng-input {
          & input {
            cursor: text;
          }
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $neutral-600;

    &:hover .ng-clear {
      color: $dark;
    }
  }

  .ng-spinner-zone {
    padding: 4px 4px 0 0;

    @include rtl {
      padding: 4px 0 0 4px;
    }
  }

  .ng-arrow-wrapper {
    width: 24px;
    height: 26px;
    padding-right: 4px;
    @extend .custom-select !optional;
    border: none !important;
    @include rtl {
      padding-left: 4px;
      padding-right: 0;
    }
  }
}

.ng-select-disabled {
  .ng-arrow-wrapper {
    background-color: #f1f2f3;
  }
}

.ng-dropdown-panel {
  margin-top: 4px !important;
  background-color: $ng-select-bg;
  border: 1px solid $ng-select-border;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
  z-index: $zindex-modal-dropdown !important;

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: $ng-select-border;
    margin-top: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        font-size: $ng-select-value-font-size;

        &:last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: $ng-select-border;
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 4px 8px;
  }

  .ng-dropdown-footer {
    border-top: 1px solid $ng-select-border;
    padding: 4px 8px;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 12px;
      font-weight: $fw-semibold;
      color: $dark;
      cursor: pointer;

      &.ng-option-disabled {
        cursor: not-allowed;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        font-weight: $fw-semibold;
      }
    }

    .ng-option {
      background-color: $ng-select-bg;
      color: $dark;
      padding: 8px 12px;

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-primary-text;
        background-color: $ng-select-selected;

        .ng-option-label {
          font-weight: $fw-normal;
        }
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }

      &.ng-option-disabled {
        color: $ng-select-primary-text;
        cursor: not-allowed;

        span {
          color: #868a8d !important;
        }
      }

      &.ng-option-child {
        padding-left: 24px;
        @include rtl {
          padding-right: 24px;
          padding-left: 0;
        }
      }

      .ng-tag-label {
        font-size: $small;
        font-weight: 400;
        padding-right: 4px;
        @include rtl {
          padding-left: 4px;
          padding-right: 0;
        }
      }
    }
  }

  @include rtl {
    direction: rtl;
    text-align: right;
  }
}
.user-group-panel-width {
  width: 420px !important; // based on max label width = 300px
}
