@import "custom-tables";
@import "bootstrap/scss/mixins";
@import "./custom-lms-layout";

.bg-success {
  @extend .bg-green-600;
}

.bg-cpd-mark {
  background-color: #123890 !important;
}
