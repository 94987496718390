@use "@angular/material" as mat;
@import "@ausmed/ausmed-styles/scss/variables";
@import "styles/custom-lms-layout";
@import "bootstrap/scss/bootstrap";
@import "swiper/swiper-bundle.min.css";
@import "./styles/_carousel.scss";
@import "./styles/custom-toast";
@import "./styles/custom-ng-select";
@import "./styles/custom-bg";
@import "./styles/b2b-buttons";
@import "./styles/b2b-inputs";
@import "./styles/b2b-badges";
@import "./styles/b2b-cards";
@import "./styles/b2b-nav-links";
@import "./styles/b2b-modal";
@import "./styles/b2b-alert.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";
@import "./styles/custom-calender.scss";
@import "./styles/custom-accordion.scss";
@import "./styles/b2b-icons";

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

@import "@ausmed/ausmed-styles/scss/type";
@import "@ausmed/ausmed-styles/scss/utilities";
@import "@ausmed/ausmed-styles/scss/layouts";
@import "./styles/custom-styles";
@import "../node_modules/floating-scroll/dist/jquery.floatingscroll.css";

@include mat.core();

$primary-theme: (
  50: $blue-100,
  100: $blue-100,
  200: $blue-200,
  300: $blue-300,
  400: $blue-400,
  500: $primary,
  600: $blue-600,
  700: $blue-700,
  800: $blue-800,
  900: $blue-800,
  A100: $blue-100,
  A200: $blue-200,
  A400: $blue-300,
  A700: $blue-400,
  contrast: (
    50: $dark,
    100: $dark,
    200: $dark,
    300: $dark,
    400: $dark,
    500: $dark,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: $dark,
    A200: $dark,
    A400: $dark,
    A700: $dark
  )
);

$custom-theme-primary: mat.m2-define-palette($primary-theme);
$custom-theme-accent: mat.m2-define-palette($primary-theme);

$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);

@include mat.all-component-themes($custom-theme);

* {
  -webkit-font-smoothing: antialiased;
}

.fade {
  transition: none !important;
}

body .bs-datepicker-container .bs-datepicker-head {
  background-color: $brand-blue-default;
}
body .bs-datepicker-container .bs-datepicker-head * {
  color: white;
}

.background-color-grey {
  background: $neutral-100;
}

.m-top-modal-class {
  z-index: 1700 !important; // more than the sidepanel z-index 1600

  .modal-content {
    border: solid 1px $neutral-400 !important;
  }
}

body {
  font-family: Inter !important;
}
